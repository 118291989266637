<template>
  <HeaderSecond />
  <Socila />

  <slot />
  <Footer />
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

import Footer from "@/components/Footer.vue";
import HeaderSecond from "@/components/HeaderSecond.vue";
import Socila from "@/components/home/Socila.vue";

export default {
  components: { HeaderSecond, Footer, Socila },
  setup() {
    const route = useRoute();

    return {
      meta: computed(() => route.meta),
    };
  },
};
</script>
