<template>
  <header>
    <div class="main-header header-bg header-second" v-if="language && Object.keys(language).length > 0">
      <Logo @click="setMenuAvtive(-1, -1)" />
      <div class="main-menu">
        <ul class="menu-list">
          <li
            class="menu-list__item tooltip"
            v-for="(item, index) in dataMenu"
            :key="index"
          >
            <router-link :to="item.link">
              <p
                v-html="language[item.title][locale]"
                @click="setMenuAvtive(index, -1)"
                :class="[
                  menuActive == index ? 'activep' : 'activep' + index,
                  'text-p',
                ]"
              ></p>
            </router-link>
            <div
              class="tooltiptext tooltip-top"
              v-if="item.sub && item.sub.length > 0"
            >
              <router-link
                :to="sub.link"
                v-for="(sub, index1) in item.sub"
                :key="index1"
              >
                <span
                  v-html="language[sub.title][locale]"
                  @click="setMenuAvtive(index, index1)"
                  :class="[
                    subActive == index1 && menuActive == index
                      ? 'activec'
                      : 'activec' + index,
                    'text-c',
                  ]"
                ></span>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
      <div class="action-h">
        <div class="language tooltip">
          <img
            src="../assets/img/icons/translate-blue.svg"
            alt="icon translate"
          />
          <img
            class="active-blue"
            src="../assets/img/icons/translate-blue.svg"
            alt="icon translate"
          />
          <div class="tooltiptext tooltip-top">
            <router-link
              to="/"
              v-for="(sub, index) in dataTranslate"
              :key="index"
            >
              <span class="text-c" v-html="sub.title" @click="setLang(sub.value)"></span>
            </router-link>
          </div>
        </div>
        <div class="search">
          <img src="../assets/img/icons/search-blue.svg" alt="icon search" />
          <img
            class="active-blue"
            src="../assets/img/icons/search-blue.svg"
            alt="icon search"
          />
          <div class="search__content">
            <input type="text" placeholder="Type your word…" />
            <button class="seach-button">SEARCH</button>
          </div>
        </div>
      </div>
      <div class="icon-mb">
        <img
          @click="openMenu()"
          src="../assets/img/icons/menu-yellow.svg"
          alt=""
        />
        <img
          class="yellow"
          @click="openMenu()"
          src="../assets/img/icons/menu-yellow.svg"
          alt=""
        />
      </div>
    </div>
    <div
      class=""
      :class="[
        menuMb == 1 ? 'menu-active' : '',
        'main-header-mb header-fixed bg-line-menu',
      ]"
    >
      <div class="icon-close">
        <div class="logo-mb">
          <a href="/">
            <img src="../assets/img/logo/logo_mb.svg" alt="" />
          </a>
        </div>
        <div class="action-mb">
          <div class="icon-close__item tooltip">
            <img src="../assets/img/icons/translate-w.svg" alt="" />
            <div class="translate__mb tooltiptext tooltip-top">
              <router-link
                to="/"
                v-for="(sub, index) in dataTranslate"
                :key="index"
              >
                <span class="text-c" v-html="sub.title" @click="setLang(sub.value)"></span>
              </router-link>
            </div>
          </div>
          <div class="icon-close__item search">
            <img src="../assets/img/icons/search-w.svg" alt="" />
            <div class="search__mb">
              <input type="text" placeholder="Type your word…" />
              <button class="seach-button">SEARCH</button>
            </div>
          </div>
          <div class="icon-close__item">
            <img
              @click="openMenu()"
              src="../assets/img/icons/close-menu-w.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="menu w-full">
        <div class="menu__content w-full" v-if="language && Object.keys(language).length > 0">
          <ul class="list-sub w-full">
            <li v-for="(item, index) in dataMenu" :key="index">
              <router-link :to="item.link" @click="openMenu()">
                <p class="text-p" v-if="language && language[item.title]" v-html="language[item.title][locale]"></p>
              </router-link>
              <div class="sub-menu">
                <router-link
                  v-for="(sub, index1) in item.sub"
                  :key="index1"
                  :to="sub.link"
                >
                  <p class="text-c" v-html="language[sub.title][locale]"></p>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
/* global $ */
import Logo from "./logo/logo.vue";
import { mapActions, mapGetters } from "vuex";
import HomeSerive from "@/services/HomeSerive";
import CommonSerive from "@/services/CommonSerive";

export default {
  name: "headerSecond",
  components: {
    Logo,
  },
  data() {
    return {
      menuMb: 0,
      activeMenu: false,
      activeSearch: false,
      dataMenu: [
        {
          title: "menu::investor",
          link: "/investor",
          sub: [
            {
              title: "menu::aboutholdings",
              link: "/investor",
            },
            {
              title: "menu::ourprojects",
              link: "/investor",
            },
          ],
        },
        {
          title: "menu::industrial",
          link: "/about",
          sub: [
            {
              title: "menu::overview",
              link: "/about",
            },
            {
              title: "menu::subdivisionfloorplan",
              link: "/about",
            },
            {
              title: "menu::product",
              link: "/about",
            },
            {
              title: "menu::infrastructure",
              link: "/about",
            },
            {
              title: "menu::attractiveindustry",
              link: "/about",
            },
          ],
        },
        {
          title: "menu::advantages",
          link: "/why-choose",
          sub: [
            {
              title: "menu::competitiveadvantage",
              link: "/why-choose",
            },
            {
              title: "menu::criticalposition",
              link: "/why-choose",
            },
            {
              title: "menu::investmentpolicy",
              link: "/why-choose",
            },
            // {
            //   title: "Tiềm năng phát triển <br> Tây Bắc Hồ Xá",
            //   link: "/why-choose",
            // },
          ],
        },
        {
          title: "menu::quangtri",
          link: "/info",
          sub: [
            {
              title: "home::connectability",
              link: "/info",
            },
            {
              title: "home::resourcesmaterials",
              link: "/info",
            },
            {
              title: "home::laborcosts",
              link: "/info",
            },
          ],
        },
        {
          title: "menu::news",
          link: "/news",
          sub: [
            {
              title: "menu::news",
              link: "/news",
            },
            {
              title: "home::recruitment",
              link: "/news",
            },
          ],
        },
        {
          title: "menu::contact",
          link: "/contact",
        },
      ],
      dataMenuActive: {
        main: -1,
        sub: -1,
      },
      dataTranslate: [
        {
          title: "Vietnamese",
          value: "vi",
        },
        {
          title: "English",
          value: "en",
        },
        {
          title: "한국인",
          value: "kr",
        },
        {
          title: "中国",
          value: "cn",
        },
        {
          title: "日本",
          value: "jp",
        },
      ],
      language:[],
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      // $(window).bind("scroll", function () {
      //   if ($(window).scrollTop() > 120) {
      //     $("header .main-header").addClass("header-bg");
      //   } else {
      //     $("header .main-header").removeClass("header-bg");
      //   }
      // });
    });
    this.dataHomea();
  },
  computed: {
    ...mapGetters(["menuActive", "subActive", "dataMenu", "locale"]),
  },
  methods: {
    ...mapActions(["setMenu", "setLang"]),
    openMenu() {
      if (this.menuMb == 0) {
        this.menuMb = 1;
        var time = 0;
        Array.from($(".list-sub li")).forEach((element) => {
          time = time + 300;
          $(element).attr("data-aos", "fade-left");
          $(element).attr("data-aos-duration", time);
        });
      } else {
        this.menuMb = 0;

        var time1 = 0;
        Array.from($(".list-sub li")).forEach((element) => {
          time1 = time1 + 300;
          $(element).removeAttr("data-aos", "fade-left");
          $(element).removeAttr("data-aos-duration", time1);
        });
      }
    },
    setMenuAvtive(main, sub) {
      this.dataMenuActive.main = main;
      this.dataMenuActive.sub = sub;
      this.setMenu(this.dataMenuActive);
      $(".text-p").removeClass("activep");
      console.log("activep" + main);
      $(".activep" + main).addClass("activep");
      $(".text-c").removeClass("activec");
      $(".activec" + sub).addClass("activec");
      this.$forceUpdate();
    },
    async dataHome() {
      await HomeSerive.getHome()
        .then((resp) => {
          this.data = resp.data.data;
          this.dataSlideA = this.data.imagesBanner;
          this.dataBranch = this.data.brands;
          this.contentBanner = this.data.banner;
          this.dataBlock = this.data.blocks;
          this.news = this.data.news;
        })
        .catch(() => {});
    },
    async dataHomea() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
        })
        .catch(() => {});
    },
    // activeMenuP(index, sub) {
    //   this.activeMenuMain(index);
    //   this.$store.commit("setMenuActive", index);
    //   this.$store.commit("setSubActive", sub);
    // },
    // activeMenuN(index, sub) {
    //   this.activeMenuMain(index);
    //   this.$store.commit("setMenuActive", index);
    //   if ((index === 0 || index === 4 || index === 5) && sub === true) {
    //     this.$store.commit("setSubActive", true);
    //   } else {
    //     this.$store.commit("setSubActive", sub);
    //   }
    // },
    // activeMenuMain(index) {
    //   $(".menu-b .item").removeClass("active");
    //   $(`.menu-b .item-m-${index}`).addClass("active");
    // },
    // setPopupMenu(data) {
    //   this.activeMenu = data;
    // },
    // setPopupSearch(data) {
    //   this.activeSearch = data;
    // },
  },
};
</script>
